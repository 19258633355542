// extracted by mini-css-extract-plugin
export var btn = "WhyShouldHire-module--btn--148f6";
export var card = "WhyShouldHire-module--card--08eb3";
export var cardHeader = "WhyShouldHire-module--card-header--930db";
export var crossImg = "WhyShouldHire-module--crossImg--a367c";
export var description = "WhyShouldHire-module--description--3dcf1";
export var description2 = "WhyShouldHire-module--description2--52338";
export var faqButton = "WhyShouldHire-module--faqButton--04f19";
export var faqsNum = "WhyShouldHire-module--faqsNum--62337";
export var faqsSub = "WhyShouldHire-module--faqsSub--0b56d";
export var head = "WhyShouldHire-module--head--d837f";
export var headr = "WhyShouldHire-module--headr--522fa";
export var mainContainer = "WhyShouldHire-module--mainContainer--ccc37";
export var quesColor = "WhyShouldHire-module--quesColor--ecaa0";
export var quesColorSelected = "WhyShouldHire-module--quesColorSelected--f18bc";
export var reverse = "WhyShouldHire-module--reverse--bc6ba";
export var serial = "WhyShouldHire-module--serial--5d2d7";
export var serial2 = "WhyShouldHire-module--serial2--442df";
export var tellBtn = "WhyShouldHire-module--tellBtn--475b9";
export var textColor = "WhyShouldHire-module--textColor--2e813";