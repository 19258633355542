import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./HowToHire.module.scss"

const HowToHire = ({ strapiData }) => {
  return (
    <div className={styles.section}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={12} lg={10}>
            <h2
              className={styles.header}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col xs={12} md={12} lg={10}>
            <p
              className={styles.desc}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </Col>
        </Row>

        <Row>
          {strapiData?.cards?.map((el, index) => {
            return (
              <Col key={el?.id} xs={12} sm={6} md={6} lg={3}>
                <div className={styles.processStep}>
                  <div className={styles.stepLabel}>
                    <p>Step 0{index + 1}</p>
                  </div>
                  <div className={styles.imageContainer}>
                    <lottie-player
                      autoplay
                      loop
                      src={el?.image1?.[0]?.localFile?.publicURL}
                      style={{ height: "84px", width: "84px", margin: "0" }}
                    />
                  </div>
                  <h3>{el?.title}</h3>
                  <p>{el?.subTitle}</p>
                </div>
              </Col>
            )
          })}
        </Row>

        <Row>
          <Col className={styles.huntContainer}>
            <h3 className={styles.huntHeading}>
              On The Hunt To Hire Top-Talented Remote Developers?
            </h3>
            <Link to="/contact-us/">
              <div className={`${styles.btn}`}>{"Hire Developers"}</div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HowToHire
