import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./OurServices.module.scss"

const OurServices = () => {
  const data = [
    {
      id: "hire_remote_services1",
      title: "Hire By Role",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/frontend_developer_b2c3d6881a.svg",
          tech: "Frontend Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/backend_developers_66df59eb46.svg",
          tech: "Backend Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/uiux_developers_4018a0a6d7.svg",
          tech: "UI/UX Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/web_developers_5d31ffe2bc.svg",
          tech: "Web Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mobile_app_developers_b382887e9f.svg",
          tech: "Mobile App Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/blockchain_developers_7df051ab46.svg",
          tech: "Blockchain Developer",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/devops_engineer_12a453f51f.svg",
          tech: "DevOps Engineers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_developers_32b451f924.svg",
          tech: "AI Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ml_engineers_fd8d74c31b.svg",
          tech: "ML Engineers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/saas_developers_ef85507a59.svg",
          tech: "SAAS Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/penetration_testing_consultant_a9ae614bff.svg",
          tech: "Penetration Testing Consultants",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/full_stack_developers_f505af8c1f.svg",
          tech: "Full Stack Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mean_pean_stack_developers_5b970cc1aa.svg",
          tech: "MEAN/PEAN Stack Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mern_pern_stack_developers_d8dda532fd.svg",
          tech: "MERN/PERN Stack Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mevn_pevn_stack_developers_49044379f1.svg",
          tech: "MEVN/PEVN Stack Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/solution_architect_a08b8d139b.svg",
          tech: "Solution Architects",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/software_developers_e53ac62c80.svg",
          tech: "Software Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/web3_developers_2c4c828489.svg",
          tech: "Web3 Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ecommerce_developers_6517d5934b.svg",
          tech: "Ecommerce Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/game_developers_31d824f841.svg",
          tech: "Game Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/metaverse_developers_0acb7e434f.svg",
          tech: "Metaverse Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cms_developers_195c2bbb02.svg",
          tech: "CMS Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/database_engineers_46c635da9b.svg",
          tech: "Database Engineers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/no_code_low_code_developers_11dc9b7386.svg",
          tech: "No Code/Low Code Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fintech_developers_dba088bee5.svg",
          tech: "Fintech Developers",
        },
      ],
    },
    {
      id: "Hire Frontend Developers",
      title: "Hire Frontend Developers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_developers_f74015a891.svg",
          tech: "React Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_developers_61eb2150ae.svg",
          tech: "Angular Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_developers_ed17605112.svg",
          tech: "Next Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_developers_4781f5920d.svg",
          tech: "Vue Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_developers_2d0ea13451.svg",
          tech: "Nuxt Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nest_js_developers_b49e43c1a6.svg",
          tech: "Nest Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_developers_5f76a5ea08.svg",
          tech: "HTML5 Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_developers_c6b9baa2ec.svg",
          tech: "CSS3 Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_developers_3dcf326081.svg",
          tech: "JavaScript Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/typescript_developers_abb8fe434e.svg",
          tech: "TypeScript Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gatsby_developers_dcfec22867.svg",
          tech: "Gatsby Developers",
        },
      ],
    },
    {
      id: "hire_remote_services3",
      title: "Hire Backend Developers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_developers_c12ac9f189.svg",
          tech: "Elixir Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/phoenix_developers_9295d356d3.svg",
          tech: "Phoenix Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_developers_d9d4718889.svg",
          tech: ".Net Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/asp_dot_net_developers_40c42bd92b.svg",
          tech: "ASP .Net developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_developers_3fcd93f3c0.svg",
          tech: "Node Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/django_developers_ffc2b6442f.svg",
          tech: "Django Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_developers_ac43fc3b48.svg",
          tech: "Python Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_on_rails_developers_597ffd8183.svg",
          tech: "Ruby on Rails Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flask_developers_0142364b6d.svg",
          tech: "Flask Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_developers_a102b984cf.svg",
          tech: "PHP Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_developers_68feeae355.svg",
          tech: "Java Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_developers_337b39f9c7.svg",
          tech: "Golang Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/laravel_developers_b2b08111a9.svg",
          tech: "Laravel Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_developers_03cb4e5244.svg",
          tech: "Express Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_developers_9390b43c0e.svg",
          tech: "C# Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteors_js_developers_cebd4d9752.svg",
          tech: "Meteor Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/electron_js_developers_268d712a73.svg",
          tech: "Electron Js Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spring_boot_developers_aad4c89459.svg",
          tech: "Spring Boot Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_developers_7ba7d88fd9.svg",
          tech: "AWS Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/api_developers_1af39cbd81.svg",
          tech: "API Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_developers_de98d7e99c.svg",
          tech: "Rust Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dat_developers_ffa4b00ee1.svg",
          tech: "Dart Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graphql_developers_98319f1f04.svg",
          tech: "GraphQL Developers",
        },
      ],
    },
    {
      id: "hire_remote_services4",
      title: "Hire Mobile App Developers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_developers_83567dd52a.svg",
          tech: "Flutter Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_developers_1237d3673f.svg",
          tech: "Android Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ios_developers_eb63968867.svg",
          tech: "iOS Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_developers_0e9adffe4a.svg",
          tech: "React Native Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_developers_de3ba40ae3.svg",
          tech: "Ionic Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_developers_90d22631de.svg",
          tech: "Kotlin Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_developers_2da44967e2.svg",
          tech: "Swift Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/objective_c_developers_6e0e94762a.svg",
          tech: "Objective-C Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cross_platform_app_developers_57cef356e6.svg",
          tech: "Cross-Platform App Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/hybrid_app_developers_72fc4d2cfd.svg",
          tech: "Hybrid App Developers",
        },
      ],
    },
    {
      id: "hire_remote_services5",
      title: "Hire DevOps Engineers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_devops_engineers_f9a4ee0ae7.svg",
          tech: "AWS DevOps Engineers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gcp_devops_engineers_b3443dd1ff.svg",
          tech: "GCP DevOps Engineers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_devops_engineers_ad68834cdc.svg",
          tech: "Azure DevOps Engineers",
        },
      ],
    },
    {
      id: "hire_remote_services9",
      title: "Hire Blockchain Developers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ethereum_developers_17d39e3f3b.svg",
          tech: "Ethereum Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/hyperledger_developers_ef54be98e9.svg",
          tech: "Hyperledger Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/solana_developers_2216cc3048.svg",
          tech: "Solana Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/algorand_developers_fdea937201.svg",
          tech: "Algorand Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cardano_developers_1072794a72.svg",
          tech: "Cardano Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cosmos_developers_a7b3a88b0c.svg",
          tech: "Cosmos Developers",
        },
      ],
    },
    {
      id: "hire_remote_services6",
      title: "Hire Database Engineers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_developers_6caee74d52.svg",
          tech: "Postgres Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_developers_45d0507676.svg",
          tech: "MySQL Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_developers_b84ff5f0ef.svg",
          tech: "MongoDB Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couchdb_developers_d8a84cd874.svg",
          tech: "CouchDB Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elastic_search_developers_23768d26ca.svg",
          tech: "Elasticsearch Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_developers_06a3b9c1e7.svg",
          tech: "Firebase Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_rds_developers_6387e82230.svg",
          tech: "AWS RDS Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/amazon_redshift_developers_bc357792d8.svg",
          tech: "Amazon Redshift Developers",
        },
      ],
    },
    {
      id: "hire_remote_services8",
      title: "Hire CMS Developers",
      desc: "We stand stalwart in the market with our comprehensive services because for us you.",
      techs: [
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wordpress_developers_d4b7eeef3d.svg",
          tech: "WordPress Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/drupal_developers_9a9c822729.svg",
          tech: "Drupal Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/strapi_developers_9e87dc98ef.svg",
          tech: "Strapi Developers",
        },
        {
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/shopify_developers_d44545513d.svg",
          tech: "Shopify Developers",
        },
      ],
    },
  ]

  const [active, setActive] = useState(data?.[0])

  return (
    <div className={styles.section}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={12} lg={10}>
            <h2 className={styles.header}>
               Our Comprehensive Services That Are Here To Stay
            </h2>
          </Col>
          <Col xs={12} md={12} lg={10}>
            <p className={styles.desc}>
              We stand stalwart in the market with our comprehensive services
              because for us you are not just another client but a partner.
              Check out our versatile services ready to cater for your every
              need.
            </p>
          </Col>
        </Row>
        <Row className={styles.servicesContainer}>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={`${styles.card} ${styles.leftCard}`}>
              {data?.map(el => (
                <div
                  onClick={() => setActive(el)}
                  key={el?.id}
                  className={`${styles.serviceTitleCard} ${
                    el?.id === active.id ? styles.active : ""
                  }`}
                >
                  <p>{el?.title}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={8} xl={8}>
            <div className={`${styles.card} ${styles.rightCard}`}>
              <div className={styles.header}>
                <h4>{active?.title} </h4>
                <p>{active?.desc}</p>
              </div>
              <Row className={styles.cardBody}>
                {active?.techs?.map(el => (
                  <Col key={el?.tech} xs={12} sm={12} md={12} lg={6}>
                    <div className={styles.techCard}>
                      <img
                        src={el?.icon}
                        alt={el?.tech}
                        loading="lazy"
                        height={26}
                        width={26}
                      />
                      <p>{el?.tech}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <Link to="/contact-us/">
              <div className={`${styles.btn}`}>{"Hire A Developer"}</div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurServices
