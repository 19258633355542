// extracted by mini-css-extract-plugin
export var active = "OurServices-module--active--34318";
export var btn = "OurServices-module--btn--bc774";
export var card = "OurServices-module--card--01076";
export var cardBody = "OurServices-module--cardBody--220af";
export var desc = "OurServices-module--desc--06db9";
export var header = "OurServices-module--header--84007";
export var leftCard = "OurServices-module--leftCard--4fdfa";
export var rightCard = "OurServices-module--rightCard--c99eb";
export var section = "OurServices-module--section--96215";
export var serviceTitleCard = "OurServices-module--serviceTitleCard--0c045";
export var servicesContainer = "OurServices-module--servicesContainer--65061";
export var techCard = "OurServices-module--techCard--ec1d5";