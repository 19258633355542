// extracted by mini-css-extract-plugin
export var bottomContent = "PortfolioMobile-module--bottomContent--eb8b2";
export var bottomHead = "PortfolioMobile-module--bottomHead--05aa9";
export var bottomPort = "PortfolioMobile-module--bottomPort--b0eb2";
export var bottomPort2 = "PortfolioMobile-module--bottomPort2--2c9c1";
export var bottomTags = "PortfolioMobile-module--bottomTags--be919";
export var bottomhead1 = "PortfolioMobile-module--bottomhead1--fc1c0";
export var bottomtag1 = "PortfolioMobile-module--bottomtag1--699b8";
export var bottomtag2 = "PortfolioMobile-module--bottomtag2--da344";
export var bottomtag3 = "PortfolioMobile-module--bottomtag3--5f30f";
export var bottomtag3Hover = "PortfolioMobile-module--bottomtag3Hover--ca8da";
export var cardWrapper = "PortfolioMobile-module--cardWrapper--a3059";
export var caseStudiesBtnPortfolio = "PortfolioMobile-module--caseStudiesBtnPortfolio--094e5";
export var description = "PortfolioMobile-module--description--a3c17";
export var firstMobileImagaPort = "PortfolioMobile-module--firstMobileImagaPort--a0706";
export var firstPortBottom = "PortfolioMobile-module--firstPortBottom--ba42c";
export var firstPortFolio = "PortfolioMobile-module--firstPortFolio--9c9bf";
export var head = "PortfolioMobile-module--head--eb6b8";
export var heading = "PortfolioMobile-module--heading--fbdec";
export var hoverFirstPortImg = "PortfolioMobile-module--hoverFirstPortImg--53088";
export var normalrFirstPortImg = "PortfolioMobile-module--normalrFirstPortImg--f5815";
export var portImage = "PortfolioMobile-module--portImage--4a62f";
export var portfolioLinkImage = "PortfolioMobile-module--portfolioLinkImage--360b9";
export var project2 = "PortfolioMobile-module--project2--c2265";
export var projectsAll = "PortfolioMobile-module--projectsAll--02f2b";
export var projectsMobileWeb = "PortfolioMobile-module--projectsMobileWeb--0a61f";
export var tag1 = "PortfolioMobile-module--tag1--128b4";
export var tag2 = "PortfolioMobile-module--tag2--117a7";
export var tag3 = "PortfolioMobile-module--tag3--23d77";
export var tags = "PortfolioMobile-module--tags--60324";