// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--0047f";
export var bannerBtn = "Banner-module--bannerBtn--95464";
export var bannerHeading = "Banner-module--bannerHeading--0f90f";
export var bannerRight = "Banner-module--bannerRight--bf5e0";
export var bannerWebCms = "Banner-module--bannerWebCms--d70a6";
export var btn_white2_border = "Banner-module--btn_white2_border--1a9ed";
export var cards = "Banner-module--cards--c5729";
export var head = "Banner-module--head--75441";
export var heading = "Banner-module--heading--a4e3e";
export var subTitle = "Banner-module--subTitle--5fb35";
export var subTitle2 = "Banner-module--subTitle2--48320";
export var talent = "Banner-module--talent--e7c6e";
export var text = "Banner-module--text--2273d";
export var tick = "Banner-module--tick--975cd";