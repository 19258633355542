// extracted by mini-css-extract-plugin
export var SliderWidth = "Portfolio-module--SliderWidth--03aaa";
export var arrowImg = "Portfolio-module--arrowImg--738f5";
export var bottomContent = "Portfolio-module--bottomContent--4ea67";
export var bottomHead = "Portfolio-module--bottomHead--0dfb8";
export var bottomPort = "Portfolio-module--bottomPort--3b3f2";
export var bottomPort2 = "Portfolio-module--bottomPort2--33171";
export var bottomTags = "Portfolio-module--bottomTags--9ae6b";
export var bottomhead1 = "Portfolio-module--bottomhead1--42ea1";
export var bottomtag1 = "Portfolio-module--bottomtag1--d99f4";
export var bottomtag2 = "Portfolio-module--bottomtag2--d1953";
export var bottomtag3 = "Portfolio-module--bottomtag3--a5d96";
export var bottomtag3Hover = "Portfolio-module--bottomtag3Hover--1c2b7";
export var cardWrapper = "Portfolio-module--cardWrapper--7db8f";
export var cards = "Portfolio-module--cards--af3bf";
export var cmsWebIconCOntainer = "Portfolio-module--cmsWebIconCOntainer--03c40";
export var cmsWebIconCOntainerLeft = "Portfolio-module--cmsWebIconCOntainerLeft--f0543";
export var description = "Portfolio-module--description--547d3";
export var heading = "Portfolio-module--heading--9e00b";
export var headingPremium = "Portfolio-module--headingPremium--20697";
export var mainWorkPortfoliosWeb = "Portfolio-module--mainWorkPortfoliosWeb--a99bd";
export var portfolioArrowIcon = "Portfolio-module--portfolioArrowIcon--d8420";
export var portfolioArrowIconCover = "Portfolio-module--portfolioArrowIconCover--5ded3";
export var portfolioArrowRightIconCover = "Portfolio-module--portfolioArrowRightIconCover--fb4ce";
export var portfolioLinkImage = "Portfolio-module--portfolioLinkImage--e63a7";
export var premiumImg = "Portfolio-module--premiumImg--59976";
export var project2 = "Portfolio-module--project2--06f7b";
export var projectsAll = "Portfolio-module--projectsAll--112c6";
export var providingImg = "Portfolio-module--providingImg--ec73a";
export var sliderWebApplicationservices = "Portfolio-module--sliderWebApplicationservices--923de";
export var teamButton = "Portfolio-module--teamButton--23ba2";
export var trailBg = "Portfolio-module--trailBg--e0210";