// extracted by mini-css-extract-plugin
export var card = "DevelopmentCompany-module--card--6b164";
export var cardWrapper = "DevelopmentCompany-module--cardWrapper--5e976";
export var description = "DevelopmentCompany-module--description--03f79";
export var developmentCompanyServices = "DevelopmentCompany-module--developmentCompanyServices--c2c05";
export var heading = "DevelopmentCompany-module--heading--64e5d";
export var loader = "DevelopmentCompany-module--loader--f1c24";
export var lodaMoreBtn = "DevelopmentCompany-module--lodaMoreBtn--db917";
export var lodalessBtn = "DevelopmentCompany-module--lodalessBtn--3da37";
export var showlessdiv = "DevelopmentCompany-module--showlessdiv--c3c08";
export var spin = "DevelopmentCompany-module--spin--4f849";
export var trailBg = "DevelopmentCompany-module--trailBg--8aeb5";