// extracted by mini-css-extract-plugin
export var ClientsWeb = "Clients-module--ClientsWeb--ae5af";
export var SliderWidth = "Clients-module--SliderWidth--eb949";
export var arrowImg = "Clients-module--arrowImg--01e79";
export var bottomOfCard = "Clients-module--bottomOfCard--b2b9f";
export var card = "Clients-module--card--51367";
export var cardWrapper = "Clients-module--cardWrapper--58b45";
export var cards = "Clients-module--cards--c4956";
export var clientData = "Clients-module--clientData--042e7";
export var clientImg = "Clients-module--clientImg--31e8e";
export var clientRating = "Clients-module--clientRating--2a19f";
export var clientsConsulting = "Clients-module--clientsConsulting--b3fa9";
export var clientsData = "Clients-module--clientsData--aed76";
export var consultHead = "Clients-module--consultHead--11208";
export var dataP = "Clients-module--dataP--2c7db";
export var description = "Clients-module--description--642a7";
export var heading = "Clients-module--heading--9bfee";
export var headingPremium = "Clients-module--headingPremium--36b2f";
export var iconContainer = "Clients-module--iconContainer--aed42";
export var iconContainerLeft = "Clients-module--iconContainerLeft--50a6a";
export var innerCardReview = "Clients-module--innerCardReview--8a86a";
export var nameDev = "Clients-module--nameDev--0bb90";
export var noCodeclients = "Clients-module--noCodeclients--a0c60";
export var portfolioArrowIcon = "Clients-module--portfolioArrowIcon--57ee1";
export var portfolioArrowIconCover = "Clients-module--portfolioArrowIconCover--d98fe";
export var portfolioArrowRightIconCover = "Clients-module--portfolioArrowRightIconCover--62557";
export var providingImg = "Clients-module--providingImg--524fc";
export var trailBg = "Clients-module--trailBg--5d8cf";