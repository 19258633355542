import React from "react"
import * as styles from "./PortfolioMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import secondPortfolio from "../../images/portfolioRevamp/portfolio-2.png"
import thirdPortfolio from "../../images/portfolioRevamp/portfolio-3.png"
import fourthPortfolio from "../../images/portfolioRevamp/portfolio-4.png"
import fifthPortfolio from "../../images/portfolioRevamp/portfolio-5.png"
import portfolioLink from "../../images/portfolioRevamp/firstPortfolioLinks.svg"
import { Link } from "gatsby"

const PortfolioMobile = ({ strapiData }) => {
  const projects = [
    {
      image: secondPortfolio,
      title: "GlobalReader",
      tag1: "Design",
      tag2: "Development",
      tag3: "AI",
    },
    {
      image: thirdPortfolio,
      title: "Homepie",
      tag1: "Design",
      tag2: "Development",
      tag3: "Real Estate",
    },
    {
      image: fourthPortfolio,
      title: "Shield Republic",
      tag1: "Design",
      tag2: "Development",
      tag3: "Ecommerce",
    },
    {
      image: fifthPortfolio,
      title: "Stitch.Health",
      tag1: "Design",
      tag2: "Development",
      tag3: "Healthcare",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.projectsMobileWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {projects &&
              projects?.map((e, i) => (
                <Col
                  lg={4}
                  md={6}
                  xs={12}
                  sm={12}
                  key={i}
                  className={styles.projectsAll}
                >
                  <div>
                    <img src={e?.image} alt="" />
                    <div
                      className={
                        i === 1 ? styles.bottomPort2 : styles.bottomPort
                      }
                    >
                      <div className={styles.bottomContent}>
                        <h3
                          className={
                            i === 0
                              ? styles.bottomhead1
                              : i === 1
                              ? styles.bottomhead1
                              : styles.bottomHead
                          }
                        >
                          {e?.title}
                          <Link
                            to={
                              i === 0
                                ? "/portfolio/globalreader/"
                                : i === 1
                                ? "/portfolio/homepie/"
                                : i === 2
                                ? "/portfolio/shield-republic/"
                                : i === 3
                                ? "/portfolio/stitch-health/"
                                : ""
                            }
                          >
                            <img
                              src={portfolioLink}
                              className={styles.portfolioLinkImage}
                              alt="portfolio"
                              decoding="async"
                              loading="lazy"
                            />
                          </Link>
                        </h3>
                        <div className={` ${styles.bottomTags}`}>
                          <div className={styles.bottomtag1}>{e?.tag1}</div>
                          <div className={styles.bottomtag2}>{e?.tag2}</div>
                          <div className={styles.bottomtag3}>{e?.tag3}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PortfolioMobile
